import React, { useState } from "react";
import api from "../../api/axios";

const ContactSection = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!formData.name.trim()) {
            formErrors.name = "Name is required";
            isValid = false;
        }

        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!formData.email.trim()) {
            formErrors.email = "Email is required";
            isValid = false;
        } else if (!emailPattern.test(formData.email)) {
            formErrors.email = "Valid email is required: ex@abc.xyz";
            isValid = false;
        }

        if (!formData.message.trim()) {
            formErrors.message = "Message is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const onSubmit = (ev) => {
        ev.preventDefault();

        if (!validateForm()) return;

        const payload = {
            name: formData.name,
            email: formData.email,
            message: formData.message,
            read: false,
        };

        api.post("/questions", payload)
            .then(() => {
                setSuccessMessage("Your message has been sent successfully.");
                setErrorMessage(""); 
                setFormData({ name: "", email: "", message: "" });
            })
            .catch((err) => {
                const response = err.response;
                if (response && response.status === 422) {
                    setErrors(response.data.errors);
                }
                console.error(err);
                setErrorMessage("An error occurred, please try again later.");
                setSuccessMessage(""); 
            });
    };

    const handleInputChange = (ev) => {
        const { name, value } = ev.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div className="d-flex justify-content-center mt-5" id="contact">
            <div className="contact-section d-block">
                <form className="contact-form validate-form" onSubmit={onSubmit}>
                    <span className="contact-form-title">Contact Us</span>

                    <div className="wrap-input rs1-wrap-input" data-validate="Name is required">
                        <span className="label-input">Your Name</span>
                        <input
                            className="input"
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                        {errors.name && <span className="error-message">{errors.name}</span>}
                        <span className="focus-input"></span>
                    </div>

                    <div className="wrap-input rs1-wrap-input" data-validate="Valid email is required: ex@abc.xyz">
                        <span className="label-input">Email</span>
                        <input
                            className="input"
                            type="text"
                            name="email"
                            placeholder="Enter your email address"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                        {errors.email && <span className="error-message">{errors.email}</span>}
                        <span className="focus-input"></span>
                    </div>

                    <div className="wrap-input" data-validate="Message is required">
                        <span className="label-input">Message</span>
                        <textarea
                            className="input"
                            name="message"
                            placeholder="Your message here..."
                            value={formData.message}
                            onChange={handleInputChange}
                        ></textarea>
                        {errors.message && <span className="error-message">{errors.message}</span>}
                        <span className="focus-input"></span>
                    </div>

                    {errorMessage && <div className="error-message" style={{ position: 'absolute', bottom:'50px' }}>{errorMessage}</div>}
                    {successMessage && <div className="success-message" style={{ position: 'absolute', bottom:'50px' }}>{successMessage}</div>}

                    <div className="container-contact-form-btn">
                        <button type="submit" className="contact-form-btn">
                            <span>
                                Submit
                                <i className="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactSection;
