import React, { useEffect, useState } from "react";

const appLogo = require("../../assets/images/inveca-modified-logo.png");

const NavBar = () => {
    const [scrolled, setScrolled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const viewportHeight = window.innerHeight;
            const scrollThreshold = viewportHeight / 2;

            setScrolled(scrollPosition > scrollThreshold);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    const headerStyle = {
        visibility: "visible",
        WebkitAnimationDuration: "0.75s",
        MozAnimationDuration: "0.75s",
        animationDuration: "0.75s",
        WebkitAnimationDelay: ".2s",
        MozAnimationDelay: ".2s",
        animationDelay: ".2s",
        height: "80px",
        background: scrolled ? "white" : "transparent",
        transition: "all 0.5s ease-in-out",
    };

    return (
        <header
            className="header-area header-sticky wow slideInDown animated"
            data-wow-duration="0.75s"
            data-wow-delay=".5s"
            style={{ ...headerStyle, position: "sticky", top: "0" }}
        >
            <div className="w-100 h-100 display-grid align-items-center">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <nav className={isOpen ? "main-nav menu-bg" : "main-nav"}>
                            <a
                                href="#home"
                                className="app-logo d-flex align-items-center"
                                onClick={(e) => {
                                    e.preventDefault();
                                    scrollToSection("home");
                                }}
                            >
                                <img src={appLogo} alt="inveca logo" />
                            </a>
                            <div className={isOpen ? "col-7 menu-open" : "col-7 menu-items"}>
                                <ul className="nav nav-light text-center">
                                    <li>
                                        <a
                                            href="#home"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                scrollToSection("top");
                                            }}
                                        >
                                            Home
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#about"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                scrollToSection("about");
                                            }}
                                        >
                                            About
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#features"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                scrollToSection("features");
                                            }}
                                        >
                                            Features
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#contact"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                scrollToSection("contact");
                                            }}
                                        >
                                            Contact
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-3 text-right">
                                <div className="menu-trigger">
                                    <button
                                        className={`hamburger hamburger--collapse ${isOpen && "is-active"}`}
                                        type="button"
                                        onClick={toggleMenu}
                                    >
                                        <span className="hamburger-box">
                                            <span className="hamburger-inner"></span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default NavBar;
