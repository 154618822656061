import React from 'react';

const ExploreSection = () => {
  const downloadApp = (ev) => {
    ev.preventDefault();
    const fileUrl = '/downloads/Inveca_App.apk';

    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'Inveca_App.apk';
    link.click();
  };

  return (
    <div className="explore-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="explore-content">
              <h2>Explore the Future of Business Efficiency</h2>
              <p>with Inveca, your dedicated partner in success.</p>
              {/* Button to trigger file download */}
              <a href="/inveca.apk" className="download-button" download>
                Download Our App
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreSection;
