import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { routes } from "../../constants/routes";

const appLogo = require("../../assets/images/inveca-modified-logo.png");

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row align-items-center align-items-stretch mb-5">
                    <div className="col-md-4 py-4 py-md-5 aside-stretch d-flex align-items-center">
                        <div className="w-100 pt-2">
                            <span className="subheading">Subscribe to our</span>
                            <h3 className="heading-section">Newsletter</h3>
                        </div>
                    </div>
                    <div className="col-md-8 py-4 py-md-5 d-flex align-items-center pl-md-5 aside-stretch-right">
                        <form action="#" className="subscribe-form w-100">
                            <div className="form-group d-flex">
                                <input
                                    type="text"
                                    className="form-control rounded-left"
                                    placeholder="Enter email address"
                                />
                                <button type="submit" className="form-control submit">
                                    <span>Submit</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="col-md-3 col-lg-6 order-md-last">
                        <div className="row justify-content-end">
                            <div className="company-info col-md-12 col-lg-9 text-md-right mb-md-0 mb-4">
                                <h2 className="footer-heading">Inveca</h2>
                                <p>
                                    Inveca is a platform designed to help businesses manage their stock efficiently and retain customers effectively. By providing personalized insights, we empower businesses to streamline operations, optimize inventory, and build lasting customer relationships.
                                </p>
                                <p className="copyright mt-5">
                                    Copyright © 2024 All rights reserved |{" "}
                                    <a href="https://inveca.co" target="_blank">
                                        inveca.co
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 col-lg-6">
                        <div className="row">
                            <div className="col-md-4 mb-md-0 mb-4">
                                <a href={routes.Home} className="app-logo d-flex align-items-center">
                                    <img src={appLogo} alt="inveca logo" />
                                </a>
                                {/* <h2 className="footer-heading">Information</h2> */}
                                {/* <ul className="list-unstyled">
                                    <li>
                                        <a href="#" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            Our Company
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            Data
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            Pricing
                                        </a>
                                    </li>
                                </ul> */}
                            </div>
                            {/* <div className="col-md-4 mb-md-0 mb-4">
                                <h2 className="footer-heading">Quick Links</h2>
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="/" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            Home
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/about-us" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            About Us
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/services" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            Our Services
                                        </a>
                                    </li>
                                    <li>
                                        <a href="contact-us" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            Contact Us
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/support" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            FAQs
                                        </a>
                                    </li>
                                </ul>
                            </div> */}
                            {/* <div className="col-md-4 mb-md-0 mb-4">
                                <h2 className="footer-heading">Products</h2>
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="#" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            Inveca Solution
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            Inveca Pro
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            Inveca Analytics
                                        </a>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
