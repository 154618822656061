import React from "react";
import { AiOutlineDatabase, AiOutlineSchedule, AiOutlineLineChart, AiOutlineRobot, AiOutlineCustomerService } from 'react-icons/ai';

const FeatureCard = ({icon, title, content}) => {
  return (
      <div className="content-card d-flex flex-column active">
        <div className="card-icon">
          {icon}
        </div>
        <div className="card-title">
          <h2>{title}</h2>
        </div>
        <div className="card-content">
          <p>{content}</p>
        </div>
      </div>
  )
}

const featureItems = [
    // {
    //     icon: <AiOutlineDatabase />,
    //     title: "Easy Data Management",
    //     content:
    //         "Keep track of all your important information in one place. This includes details like customer info, visit purposes, stock levels, and any notes or comments you need to remember.",
    // },
    {
        icon: <AiOutlineRobot />,
        title: "Stock Tracking",
        content:
            "Monitor your stock levels, so you always know when to restock or take action. This helps you make informed decisions and never run out of key products.",
    },
    {
        icon: <AiOutlineLineChart />,
        title: "Instant Reports",
        content:
            "Get reports in real-time to see how your business is performing. This helps you make quick decisions and improve how you follow up with customers.",
    },
    {
        icon: <AiOutlineCustomerService />,
        title: "Customer Behavior Tracking",
        content:
            "The app analyzes purchasing patterns, helping businesses identify popular products and tailor offerings to customer preferences.",
    },
];

const OurFeatures = () => {
    return (
        <div id="features" className="our-features">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-12 align-self-center wow fadeInRight animated">
                                <div className="about-content">
                                    <h3>Our Features</h3>
                                    <h2>Providing Solutions to Streamlined Operations</h2>
                                    <p>
                                        Unlock business potential with Inveca and elevate performance effortlessly. It's not just what you want, it's what you
                                        need.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-around flex-wrap">
                            {featureItems.map((item, index) => (
                                <div className="col-lg-3 ml-3 mr-3" key={index}>
                                    <FeatureCard icon={item.icon} title={item.title} content={item.content} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurFeatures;
